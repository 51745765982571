<template>
  <scroll-container>
    <container>
      <card-section>
        <template #title> Reset your password </template>

        <card-list>
          <card-list-field>
            <span>Email</span>

            <field v-model="email" placeholder="Confirm your email address" />
          </card-list-field>

          <card-list-button :action="reset"> Submit + reset.. </card-list-button>
        </card-list>
      </card-section>
    </container>
  </scroll-container>
</template>

<script>
import PageMixin from '../mixins/Page'

export default {
  mixins: [PageMixin],

  methods: {
    reset() {}
  },

  data() {
    return {
      email: ''
    }
  }
}
</script>
